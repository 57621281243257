import React from "react";
import moment from "moment";
import { graphql } from "gatsby";
import { Parallax } from "react-parallax";
import Layout from "../components/layouts";
import { truncateText, lightenDarkenColor } from "../utils";
import { RichText } from "prismic-reactjs";

const IconSlice = ({ content, themeColor }) => {
  const { primary: iconPrimary } = content;
  const { icon } = iconPrimary;
  const { primary } = themeColor;
  const { color } = primary;
  
  return (
    <div className="program-body">
      <div className="program-body-inner">
        <div className="program-icon-container" style={{background: `linear-gradient(-220deg, ${color} 0%, ${lightenDarkenColor({col: color, amt: -35})} 100%)`}}>
            <div className="program-icon" style={{backgroundImage: `url(${icon.url})`}} />
        </div>
      </div>
    </div>
  )
};

const HeaderSlice = ({ content }) => {
  const { primary } = content;
  const { header } = primary;
  return (
    <Parallax className="page-header" bgImage={header.url} strength={400}>
      <div style={{height: '100vh', maxHeight: '550px'}}>
          <div className="page-header-overlay" />
          <h1 className="page-header-title white">Join us</h1>
      </div>
    </Parallax>
  )
};

const Description = ({ content }) => {
  const { primary } = content;
  const { description } = primary;
  return (
    <div className="signup-body">
      <div className="signup-intro">
        { RichText.render(description) }
      </div>
    </div>
  )
};

const SignupModule = ({ content }) => {
  const { fields } = content;
  console.log(fields);
  return (
    <div className="signup-body">
      <div className="modules-container">
      <div className="modules-container-inner flex">
          {fields.map(item => {
            const { sign_up_type } = item;
            const { body, _meta } = sign_up_type;
            const headerUrl = body?.find(x => x.type === 'header_slice')?.primary?.header?.url;
            const icon = body?.find(x => x.type === 'icon')?.primary?.icon?.url;
            const themeColor = body?.find(x => x.type === 'theme_color')?.primary?.color || "#ffffff";
            const title = _meta?.uid?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            const excerpt = body?.find(x => x.type === 'excerpt')?.primary?.excerpt || "";
            return (
              <div className="module" key={title}>
              <div className="module-shadow-container">
                  <div className="thumb" style={{backgroundImage: `url(${headerUrl})`}}>
                      <div className="icon" style={{background: `linear-gradient(-220deg, ${themeColor} 0%, ${lightenDarkenColor({ col: themeColor, amt: -35 })} 100%)`}}>
                          <div className="icon-svg" style={{backgroundImage: `url(${icon})`}}></div>
                      </div>
                  </div>

                  <div className="inner">
                      <h1>{title}</h1>
                      <p dangerouslySetInnerHTML={{__html: truncateText({ input: excerpt, len: 29})}}/>
                  </div>

                  <a href={`/sign-up/${_meta?.uid}`} className="no-effects">
                      <div className="btn primary small">
                          <span>Learn More</span>
                      </div>
                  </a>
              </div>
          </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

const Form = ({ content }) => {
  const { primary } = content;
  const { form_title, html_embed } = primary;

  return (
    <div className="signup-body">
      <div className="form-container application-form-container">
        <div className="form-inner application-form-inner">
          <h1>{form_title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html_embed }} />
        </div>
      </div>
    </div>
  )
};

const sliceComponents = {
  header_slice: HeaderSlice,
  description_slice: Description,
  sign_up_slice: SignupModule,
  form_slice: Form,
};

export default ({ data }) => {
  const doc = data.prismic.allSign_up_pages.edges.slice(0,1).pop();
  const themeColor = doc.node?.body?.find(x => x.type === "theme_color_slice");
  if(!doc) return null;
  return(
    <Layout>
      <div className="signup-page">
        <div className="signup-inner">
          {doc.node?.body.map((slice, i) => {
              if (!slice?.type) return null;
              const SliceComponent =
                sliceComponents[slice.type];

                if (SliceComponent) {
                  return (
                    <SliceComponent
                      content={slice}
                      key={slice.id || i}
                      themeColor={themeColor}
                    />
                  );
                }

                return null;
            })}
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
query SignUpPageQuery {
  prismic {
    allSign_up_pages {
      edges {
        node {
          body {
            ... on PRISMIC_Sign_up_pageBodyHeader_slice {
              type
              label
              primary {
                header
              }
            }
            ... on PRISMIC_Sign_up_pageBodyDescription_slice {
              type
              label
              primary {
                description
              }
            }
            ... on PRISMIC_Sign_up_pageBodySign_up_slice {
              type
              label
              fields {
                sign_up_type {
                  ... on PRISMIC_Sign_up {
                    _linkType
                    _meta {
                      uid
                    }
                    body {
                      ... on PRISMIC_Sign_upBodyHeader_slice {
                        type
                        label
                        primary {
                          header
                        }
                      }
                      ... on PRISMIC_Sign_upBodyDescription_slice {
                        type
                        label
                        primary {
                          description
                        }
                      }
                      ... on PRISMIC_Sign_upBodyTheme_color {
                        type
                        label
                        primary {
                          color
                        }
                      }
                      ... on PRISMIC_Sign_upBodyIcon {
                        type
                        label
                        primary {
                          icon
                        }
                      }
                      ... on PRISMIC_Sign_upBodyExcerpt {
                        type
                        label
                        primary {
                          excerpt
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PRISMIC_Sign_up_pageBodyForm_slice {
              type
              label
              primary {
                form_title
                html_embed
              }
            }
          }
        }
      }
    }
  }
}
`